@font-face {
  font-family: AuthorizedSignature;
  src: url(./fonts/AuthorizedSignature.ttf) format(truetype);
}

@font-face {
  font-family: Century;
  src: url(./fonts/Century.ttf) format(truetype);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}